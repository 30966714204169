<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-briefcase fa-md mr-1"></i> <span class="h5">{{datosActualizarCliente.razonSocial}} </span>
                        </CCardHeader>
                        <CCardBody>
                            <b-tabs variant="pills">
                                <b-tab active>
                                    <template slot="title">
                                        <i class="fas fa-receipt fa-md"></i> Información
                                    </template>

                                    <div class="mt-3">
                                        <b-row>
                                            <b-col md="12">
                                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                                    <b-form @submit.stop.prevent="handleSubmit(modificarCliente)">
                                                        <b-row>
                                                            <b-col lg="4">
                                                                <validation-provider name="número de ruc" :rules="{ required: true,numeric:true,min:11,max:11 }" v-slot="validationContext">
                                                                    <b-form-group label="Número de ruc:" class="mb-2">

                                                                        <b-input-group>
                                                                            <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese número de RUC" v-model="datosActualizarCliente.numeroRuc"></b-form-input>
                                                                            <b-input-group-append>

                                                                            </b-input-group-append>
                                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                        </b-input-group>

                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="4">
                                                                <validation-provider name="razón social" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Razón social:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosActualizarCliente.razonSocial"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="4">
                                                                <validation-provider name="nombre comercial" v-slot="validationContext">
                                                                    <b-form-group label="Nombre comercial:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre comercial" v-model="datosActualizarCliente.nombreComercial"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="departamento" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Departamento:" class="mb-2">
                                                                        <v-select :reduce="listaDepartamentos => listaDepartamentos.idDepartamento" label="nombre" @input="obtenerProvincia()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarCliente.idDepartamento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarCliente.idDepartamento" :options="listaDepartamentos" >
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="4">
                                                                <validation-provider name="provincia" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Provincia:" class="mb-2">
                                                                        <v-select :reduce="listaProvincias => listaProvincias.idProvincia" label="nombre" @input="obtenerDistrito()" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarCliente.idProvincia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarCliente.idProvincia" :options="listaProvincias" >
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="4">
                                                                <validation-provider name="distrito" rules="required" v-slot="{errors}">
                                                                    <b-form-group label="Distrito:" class="mb-2">
                                                                        <v-select :reduce="listaDistritos => listaDistritos.idDistrito" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarCliente.idDistrito , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarCliente.idDistrito" :options="listaDistritos">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="3">
                                                                <validation-provider name="localidad" v-slot="validationContext">
                                                                    <b-form-group label="Localidad:" class="mb-2">
                                                                        <b-form-input autocomplete="off" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la localidad" v-model="datosActualizarCliente.localidad"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="3">
                                                                <validation-provider name="código postal" v-slot="validationContext">
                                                                    <b-form-group label="Código Postal:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el código postal" v-model="datosActualizarCliente.codigoPostal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="6">
                                                                <validation-provider name="dirección fiscal" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Dirección fiscal:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la dirección fiscal" v-model="datosActualizarCliente.direccionFiscal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="contacto" v-slot="validationContext">
                                                                    <b-form-group label="Contacto:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el contacto" v-model="datosActualizarCliente.contacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="teléfono del contacto" :rules="{ numeric: true }" v-slot="validationContext">
                                                                    <b-form-group label="Teléfono del contacto:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono del contacto" v-model="datosActualizarCliente.telefonoContacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="correo del contacto" :rules="{ email: true }" v-slot="validationContext">
                                                                    <b-form-group label="Correo del contacto:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el correo del contacto" v-model="datosActualizarCliente.correoContacto"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>

                                                            <b-col lg="4">
                                                                <validation-provider name="actividad económica" v-slot="validationContext">
                                                                    <b-form-group label="Actividad económica:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el actividad económica" v-model="datosActualizarCliente.actividadEconomica"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="teléfono" :rules="{ numeric: true }" v-slot="validationContext">
                                                                    <b-form-group label="Teléfono:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarCliente.telefono"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="correo" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Correo:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosActualizarCliente.correo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="web" v-slot="validationContext">
                                                                    <b-form-group label="Web:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese la web" v-model="datosActualizarCliente.web"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="representante legal" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Representante legal:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosActualizarCliente.representanteLegal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="4">
                                                                <validation-provider name="cargo" :rules="{ required: true }" v-slot="validationContext">
                                                                    <b-form-group label="Cargo:" class="mb-2">
                                                                        <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el cargo" v-model="datosActualizarCliente.cargo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="servicios" v-slot="{errors}">
                                                                    <b-form-group label="Servicios:" class="mb-2">
                                                                        <v-select :reduce="listaServicios => listaServicios.idServicio" multiple label="descripcion" placeholder="Seleccione una o varias opciones" :class="{'style-valid-select': datosActualizarCliente.servicios.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosActualizarCliente.servicios" :options="listaServicios">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col lg="12">
                                                                <validation-provider name="observaciones" :rules="{  }" v-slot="validationContext">
                                                                    <b-form-group label="Observaciones:" class="mb-2">
                                                                        <b-form-textarea :state="getValidationState(validationContext)" rows="3" max-rows="6" size="md" placeholder="Ingrese las observaciones" v-model="datosActualizarCliente.observaciones"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12 text-center my-3">
                                                                <b-button :to="{name: 'Clientes cliente'}" variant="dark" class=" mr-2">
                                                                    <i class="fas fa-arrow-left"></i> Volver
                                                                </b-button>
                                                                <b-button variant="success" type="submit">
                                                                    <i class="fas fa-save"></i> Guardar
                                                                </b-button>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form>
                                                </validation-observer>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template slot="title">
                                        <i class="fas fa-users fa-md"></i> Usuarios
                                    </template>
                                    <div class="mt-3">
                                        <b-row>
                                            <b-col md="12">
                                                <b-button size="sm" variant="dark" class="float-right" @click="modalRegistrarUsuario = true">
                                                    <i class="fas fa-plus fa-sm"></i><span> Agregar</span>
                                                </b-button>
                                            </b-col>
                                            <b-col md="12" class="mt-2">
                                                <b-table bordered hover show-empty mediun responsive outlined :items="listaUsuarios" :fields="camposUsuarios" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                    <template v-slot:cell(nombre)="param">{{param.item.nombre}} {{param.item.apellidoPaterno}} {{param.item.apellidoMaterno}}</template>
                                                    <template #cell(acciones)="param">
                                                        <b-button @click="abrirModalModificarUsuario(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-search my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <b-button v-if="param.item.estado==1" @click="restaurarUsuario(param)" class="mr-1 mb-1" size="sm" variant="warning" v-c-tooltip="'Restaurar'">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-reply my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                        <b-button v-if="param.item.estado==2" @click="eliminarUsuario(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                            <span class="btn-inner--icon">
                                                                <i class="fas fa-trash-alt my-0 mx-0"></i>
                                                            </span>
                                                        </b-button>
                                                    </template>

                                                </b-table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-tab>

                            </b-tabs>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarUsuario" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Nuevo usuario</span></h6>

            <CButtonClose @click="modalRegistrarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarUsuario)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosNuevoUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido paterno" v-model="datosNuevoUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido materno" v-model="datosNuevoUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="correo" :rules="{email: true,required:true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el correo" v-model="datosNuevoUsuario.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el teléfono" v-model="datosNuevoUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de documento:">
                                <b-form-select :state="getValidationState(validationContext)" value-field="idTipoDocumento" text-field="descripcion" v-model="datosNuevoUsuario.idTipoDocumento" :options="comboTipoDocumentos">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento de identidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el documento de identidad" v-model="datosNuevoUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el usuario" v-model="datosNuevoUsuario.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contraseña" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Contraseña:">
                                <b-form-input type="password" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la contrasena" v-model="datosNuevoUsuario.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarUsuario" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar usuario</span></h6>
            <CButtonClose @click="modalActualizarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarUsuario)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido paterno" v-model="datosModificarUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido Materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuarioActualizar()" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el apellido materno" v-model="datosModificarUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="correo" :rules="{email: true,required:true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el correo" v-model="datosModificarUsuario.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el teléfono" v-model="datosModificarUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="tipo de documento" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de documento:">
                                <b-form-select :state="getValidationState(validationContext)" value-field="idTipoDocumento" text-field="descripcion" v-model="datosModificarUsuario.idTipoDocumento" :options="comboTipoDocumentos">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="documento de identidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el documento de identidad" v-model="datosModificarUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="usuario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Usuario:">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el usuario" v-model="datosModificarUsuario.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="contraseña" v-slot="validationContext">
                            <b-form-group label="Contraseña:">
                                <b-form-input type="password" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la contrasena (opcional)" v-model="datosModificarUsuario.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            base_host: CONSTANTES.URL_RUTA_FRONTEND,
            listaDistritos: [],
            listaProvincias: [],
            listaDepartamentos: [],

            listaClientes: [],
            cargaApi: false,
            disabledBtnApi: true,

            datosActualizarCliente: {
                idSubCliente: '',
                idCliente: null,
                numeroRuc: '',
                razonSocial: '',
                nombreComercial: '',
                idDepartamento: null,
                idProvincia: null,
                idDistrito: null,
                localidad: '',
                codigoPostal: '',
                direccionFiscal: '',
                contacto: '',
                telefonoContacto: '',
                correoContacto: '',
                actividadEconomica: '',
                telefono: '',
                correo: '',
                web: '',
                representanteLegal: '',
                servicios: [],
                cargo: '',
                observaciones: '',
                estado: 2
            },
            // datosCliente: {
            //     idCliente: '',
            //     // idSubCliente:'',
            //     numeroRuc: '',
            //     razonSocial: '',
            //     nombreComercial: '',
            //     idDepartamento: null,
            //     idProvincia: null,
            //     idDistrito: null,
            //     localidad: '',
            //     codigoPostal: '',
            //     direccionFiscal: '',
            //     contacto: '',
            //     telefonoContacto: '',
            //     correoContacto: '',
            //     actividadEconomica: '',
            //     telefono: '',
            //     correo: '',
            //     web: '',
            //     representanteLegal: '',
            //     servicios: [],
            //     cargo: '',
            //     observaciones: '',
            //     estado: 2
            // },

            camposUsuarios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "usuario",
                    label: "Usuario",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaUsuarios: [],

            datosNuevoUsuario: {
                idCliente: '',
                idSubCliente: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                correo: '',
                telefono: '',
                numeroDocumento: '',
                idTipoDocumento: null,
                idPuestoTrabajo: 0,
                hasUsuario: 2,
                idTipoPerfil: 0,
                usuario: '',
                contrasena: '',
            },

            datosModificarUsuario: {
                idUsuario: '',
                idPersona: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                correo: '',
                telefono: '',
                numeroDocumento: '',
                idTipoDocumento: null,
                idPuestoTrabajo: 0,
                hasUsuario: 2,
                idTipoPerfil: 0,
                usuario: '',
                contrasena: '',
            },

            comboTipoPerfiles: [],
            comboTipoDocumentos: [],
            comboPuestosTrabajo: [],

            modalRegistrarUsuario: false,
            modalActualizarUsuario: false,

            disabled: false,
            // datosActualizarCliente: {
            //     idSubCliente: '',
            //     idCliente: null,
            //     numeroRuc: '',
            //     razonSocial: '',
            //     nombreComercial: '',
            //     idDepartamento: null,
            //     idProvincia: null,
            //     idDistrito: null,
            //     localidad: '',
            //     codigoPostal: '',
            //     direccionFiscal: '',
            //     contacto: '',
            //     telefonoContacto: '',
            //     correoContacto: '',
            //     actividadEconomica: '',
            //     telefono: '',
            //     correo: '',
            //     web: '',
            //     representanteLegal: '',
            //     servicios: [],
            //     cargo: '',
            //     observaciones: '',
            //     estado: 3
            // },

            comboPerfiles: [],

            listaServicios: [{
                    idServicio: 1,
                    descripcion: 'Certfificación'
                },
                {
                    idServicio: 2,
                    descripcion: 'Inspección'
                },
                {
                    idServicio: 3,
                    descripcion: 'Homologación'
                }
            ],

        }
    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        generarNombreUsuario() {
            let me = this;
            if (me.datosNuevoUsuario.hasUsuario == 2) {
                let usuario = me.datosNuevoUsuario.nombre.substr(0, 1) + me.datosNuevoUsuario.apellidoPaterno + me.datosNuevoUsuario.apellidoMaterno.substr(0, 1);
                me.datosNuevoUsuario.usuario = usuario.toUpperCase();
            }

        },
        generarNombreUsuarioActualizar() {
            let me = this;
            if (me.datosModificarUsuario.hasUsuario == 2) {
                let usuario = me.datosModificarUsuario.nombre.substr(0, 1) + me.datosModificarUsuario.apellidoPaterno + me.datosModificarUsuario.apellidoMaterno.substr(0, 1);
                me.datosModificarUsuario.usuario = usuario.toUpperCase();
            }

        },

        obtenerTipoDocumento() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumentos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        /* obtenerTipoPerfiles() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "", {
                    params: {

                    }
                })
                .then(function (response) {
                    me.comboTipoPerfiles = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "", {
                    params: {
                        idCliente: me.datosNuevoUsuario.idCliente
                    }
                })
                .then(function (response) {
                    me.comboPuestosTrabajo = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        }, */
        // verDetalleCliente(param) {
        //     let me = this;
        //     me.datosActualizarCliente.idSubCliente = param.item.idSubCliente
        //     me.datosActualizarCliente.idCliente = param.item.idCliente;
        //     me.datosActualizarCliente.numeroRuc = param.item.numeroRuc;
        //     me.datosActualizarCliente.razonSocial = param.item.razonSocial;
        //     me.datosActualizarCliente.nombreComercial = param.item.nombreComercial;
        //     me.obtenerDepartamento();
        //     me.datosActualizarCliente.idDepartamento = param.item.idDepartamento;
        //     me.obtenerProvincia();
        //     me.datosActualizarCliente.idProvincia = param.item.idProvincia;
        //     me.obtenerDistrito();
        //     me.datosActualizarCliente.idDistrito = param.item.idDistrito;
        //     me.datosActualizarCliente.localidad = param.item.localidad;
        //     me.datosActualizarCliente.codigoPostal = param.item.codigoPostal;
        //     me.datosActualizarCliente.direccionFiscal = param.item.direccionFiscal;
        //     me.datosActualizarCliente.contacto = param.item.contacto;
        //     me.datosActualizarCliente.telefonoContacto = param.item.telefonoContacto;
        //     me.datosActualizarCliente.correoContacto = param.item.correoContacto;
        //     me.datosActualizarCliente.actividadEconomica = param.item.actividadEconomica;
        //     me.datosActualizarCliente.telefono = param.item.telefono;
        //     me.datosActualizarCliente.correo = param.item.correo;
        //     me.datosActualizarCliente.web = param.item.web;
        //     me.datosActualizarCliente.nombreLogo = param.item.nombreLogo;
        //     me.datosActualizarCliente.representanteLegal = param.item.representanteLegal;
        //     me.datosActualizarCliente.cargo = param.item.cargo;
        //     me.datosActualizarCliente.observaciones = param.item.observaciones;
        //     me.datosActualizarCliente.estado = param.item.estado;

        //     me.modalActualizarPosibleCliente = true;
        // },
    
        apiSunat() {
            let me = this;
            let mi_token = "76afdc4b32381ff72ff7a4d827f7856bb850e6228adc9e9f6f1c74e114510f22";
            me.cargaApi = true;
            axios
                .get("https://apiperu.dev/api/ruc/" + me.datosActualizarCliente.numeroRuc, {
                    headers: {
                        Authorization: `Bearer ${mi_token}`
                    },
                    responseType: "json",
                })
                .then((response) => {
                    if (response.data.data) {
                        me.datosActualizarCliente.razonSocial = response.data.data.nombre_o_razon_social;
                        me.datosActualizarCliente.direccionFiscal = response.data.data.direccion;
                        me.datosActualizarCliente.idDepartamento = response.data.data.ubigeo[0]
                        me.obtenerProvincia();
                        me.datosActualizarCliente.idProvincia = response.data.data.ubigeo[1]
                        me.obtenerDistrito();
                        me.datosActualizarCliente.idDistrito = response.data.data.ubigeo[2]
                    } else {
                        me.swat('error', 'No se encontró el número de ruc!')
                    }
                    me.cargaApi = false;
                })
                .catch((error) => {
                    me.cargaApi = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        consultarCliente() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/obtener-cliente-iqc", {
                    params: {
                        idSubCliente: this.$route.params.id
                    }
                })
                .then(function (response) {
                    me.datosActualizarCliente.idSubCliente = response.data[0].idSubCliente;
                    me.datosNuevoUsuario.idSubCliente = response.data[0].idSubCliente;
                    me.datosActualizarCliente.idCliente = response.data[0].idCliente;
                    me.datosActualizarCliente.representanteLegal = response.data[0].representanteLegal;
                    me.datosActualizarCliente.contacto = response.data[0].contacto;
                    me.datosActualizarCliente.telefonoContacto = response.data[0].telefonoContacto;
                    me.datosActualizarCliente.correoContacto = response.data[0].correoContacto;
                    me.datosActualizarCliente.nombreComercial = response.data[0].nombreComercial;
                    me.datosActualizarCliente.localidad = response.data[0].localidad;
                    me.datosActualizarCliente.direccionFiscal = response.data[0].direccionFiscal;
                    me.datosActualizarCliente.codigoPostal = response.data[0].codigoPostal;
                    me.datosActualizarCliente.cargo = response.data[0].cargo;
                    me.datosActualizarCliente.actividadEconomica = response.data[0].actividadEconomica;
                    me.datosActualizarCliente.observaciones = response.data[0].observaciones;
                    me.datosActualizarCliente.numeroRuc = response.data[0].numeroRuc;
                    me.datosActualizarCliente.razonSocial = response.data[0].razonSocial;
                     me.obtenerDepartamento();
                    me.datosActualizarCliente.idDepartamento = response.data[0].idDepartamento;
                    me.obtenerProvincia();
                    me.datosActualizarCliente.idProvincia = response.data[0].idProvincia;
                    me.obtenerDistrito();
                    me.datosActualizarCliente.idDistrito = response.data[0].idDistrito;
                    me.datosActualizarCliente.responsable = response.data[0].responsable;
                    me.datosActualizarCliente.telefono = response.data[0].telefono;
                    me.datosActualizarCliente.celular = response.data[0].celular;
                    me.datosActualizarCliente.fax = response.data[0].fax;
                    me.datosActualizarCliente.correo = response.data[0].correo;
                    me.datosActualizarCliente.web = response.data[0].web;
                    me.datosActualizarCliente.ubicacion = response.data[0].ubicacion;
                    me.datosActualizarCliente.estado = response.data[0].estado;

                    me.datosActualizarCliente.servicios = JSON.parse(response.data[0].servicios);
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: "Clientes cliente"
                    })
                });
        },

        listarUsuarios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-sub-usuarios-iqc", {
                    params: {
                        idCliente: me.datosNuevoUsuario.idCliente,
                        idSubCliente: me.$route.params.id
                    }
                })
                .then(function (response) {
                    me.listaUsuarios = response.data;
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: "Clientes cliente"
                    })
                });
        },
             obtenerDepartamento() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-departamento")
                .then(function (response) {
                    me.listaDepartamentos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');

                });
        },

    obtenerProvincia() {
            let me = this;
            (me.datosActualizarCliente.idProvincia = null),
            (me.datosActualizarCliente.idDistrito = null),
            (me.listaDistritos = []),

            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-provincia", {
                    params: {
                        idDepartamento: me.datosActualizarCliente.idDepartamento,
                    },
                })
                .then(function (response) {
                    me.listaProvincias = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        obtenerDistrito() {
            let me = this;
            (me.datosActualizarCliente.idDistrito = null),
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-distrito", {
                    params: {
                        idProvincia: me.datosActualizarCliente.idProvincia,
                    },
                })
                .then(function (response) {
                    me.listaDistritos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarCliente() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-cliente-iqc",
                    me.datosActualizarCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje)
                    me.consultarCliente();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        registrarUsuario() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/registrar-usuario-iqc",
                    me.datosNuevoUsuario, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarUsuarios();
                    me.modalRegistrarUsuario = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarUsuario() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/modificar-usuario-iqc",
                    me.datosModificarUsuario, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarUsuario = false
                    me.listarUsuarios();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },

        abrirModalModificarUsuario(param) {

            let me = this;
            me.datosModificarUsuario.idUsuario = param.item.idUsuario;
            me.datosModificarUsuario.idPersona = param.item.idPersona;
            me.datosModificarUsuario.nombre = param.item.nombre;
            me.datosModificarUsuario.apellidoPaterno = param.item.apellidoPaterno;
            me.datosModificarUsuario.apellidoMaterno = param.item.apellidoMaterno;
            me.datosModificarUsuario.correo = param.item.correo;
            me.datosModificarUsuario.telefono = param.item.telefono;
            me.datosModificarUsuario.numeroDocumento = param.item.numeroDocumento;
            me.datosModificarUsuario.idTipoDocumento = param.item.idTipoDocumento;
            me.datosModificarUsuario.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosModificarUsuario.hasUsuario = param.item.hasUsuario;
            me.datosModificarUsuario.idTipoPerfil = param.item.idTipoPerfil;
            me.datosModificarUsuario.usuario = param.item.usuario;
            me.modalActualizarUsuario = true;

        },
        resetModalRegistrarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });

            this.datosNuevoUsuario.nombre = '';
            this.datosNuevoUsuario.apellidoPaterno = '';
            this.datosNuevoUsuario.apellidoMaterno = '';
            this.datosNuevoUsuario.numeroDocumento = '';
            this.datosNuevoUsuario.correo = '';
            this.datosNuevoUsuario.idTipoDocumento = null;

            this.datosNuevoUsuario.telefono = '';
            this.datosNuevoUsuario.usuario = '';
            this.datosNuevoUsuario.idTipoPerfil = '';
            this.datosNuevoUsuario.idTipoDocumento = '';
            this.datosNuevoUsuario.contrasena = '';

        },
        eliminarUsuario(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el usuario?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/eliminar-usuario-iqc", {
                            idUsuario: param.item.idUsuario,
                            idPersona: param.item.idPersona,
                            estado: 1,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarUsuarios();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        restaurarUsuario(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de restaurar el usuario?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#F9B115',
                confirmButtonText: 'Restaurar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/restaurar-usuario-iqc", {
                            idUsuario: param.item.idUsuario,
                            idPersona: param.item.idPersona,
                            estado: 2,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarUsuarios();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        resetModalActualizarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });
            this.datosModificarUsuario.contrasena = '';
        },

        resetDatosCliente() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    computed: {
    

    },
    watch: {
        modalRegistrarUsuario: function (val) {
            if (val == false) {
                this.resetModalRegistrarUsuario();
            }
        },
        modalActualizarUsuario: function (val) {
            if (val == false) {
                this.resetModalActualizarUsuario();
            }
        },

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosActualizarCliente.idCliente = this.datosNuevoUsuario.idCliente = this.datosModificarUsuario.idCliente = user.uidClient;
            this.consultarCliente();
            //this.listarPuestosTrabajo();
            this.obtenerTipoDocumento();
            //this.obtenerTipoPerfiles();
            this.listarUsuarios();
        }

    }

}
</script>
